<template>
  <popup v-model="visible" class-name="popup-how-it-works">
    <div class="popup-how-it-works__container u-text-center">
      <div class="popup-how-it-works__what">
        <div
          class="popup-how-it-works__what-question font-h2"
          v-html="$t('howItWorks.what.question')"
        ></div>
        <div
          class="
            popup-how-it-works__title popup-how-it-works__what-title
            font-h0
          "
          v-html="$t('howItWorks.what.title')"
        ></div>
        <div class="popup-how-it-works__what-items">
          <div
            v-for="{ image, title, text } in $t('howItWorks.what.items')"
            :key="title"
            class="popup-how-it-works__what-item"
          >
            <div
              v-lazy:background-image="image"
              class="popup-how-it-works__what-item-image u-bg-cover"
            ></div>
            <div
              class="popup-how-it-works__what-item-title font-semibold"
              v-html="title"
            ></div>
            <div
              class="
                popup-how-it-works__what-item-text
                font-text
                u-text-secondary
              "
              v-html="text"
            ></div>
          </div>
        </div>
      </div>
      <div class="popup-how-it-works__benefits">
        <div class="popup-how-it-works__benefits-container">
          <div
            v-for="{ icon, title, text } in $t('howItWorks.benefits')"
            :key="icon"
            class="popup-how-it-works__benefits-item"
          >
            <div class="popup-how-it-works__benefits-card">
              <div
                class="
                  popup-how-it-works__benefits-icon
                  u-fill-deep-current u-stroke-deep-current
                "
              >
                <svg-inline>
                  <icon :icon="icon" />
                </svg-inline>
              </div>
              <div
                class="popup-how-it-works__benefits-title font-semibold"
                v-html="title"
              ></div>
              <div
                class="
                  popup-how-it-works__benefits-text
                  font-text
                  u-text-secondary
                "
                v-html="text"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="popup-how-it-works__steps">
        <div
          class="popup-how-it-works__title font-h0"
          v-html="$t('howItWorks.steps.title')"
        ></div>
        <div class="popup-how-it-works__steps-list">
          <div
            v-for="({ title, text }, index) in $t('howItWorks.steps.list')"
            :key="title"
            class="popup-how-it-works__steps-item"
          >
            <div class="popup-how-it-works__steps-index font-caps">
              <div class="popup-how-it-works__steps-index-container">
                {{ index + 1 }}
              </div>
            </div>
            <div
              class="popup-how-it-works__steps-title font-semibold"
              v-html="title"
            ></div>
            <div
              class="popup-how-it-works__steps-text font-text u-text-secondary"
              v-html="text"
            ></div>
          </div>
        </div>
      </div>
      <div class="popup-how-it-works__close">
        <Button
          type="button"
          tag="button"
          color="gray"
          class="u-w-full"
          :text="$t('howItWorks.close')"
          @click="visible = false"
        />
      </div>
    </div>
  </popup>
</template>

<script>
import Popup from "@/components/ui-kit/popup/index.vue";
import SvgInline from "@/components/ui-kit/svg-inline/index.vue";
import Icon from "@/components/icons/index.vue";
import Button from "@/components/ui-kit/button/index.vue";

export default {
  components: {
    Popup,
    SvgInline,
    Icon,
    Button,
  },
  data() {
    return {
      visible: false,
    };
  },
  mounted() {
    fakeWindow.addEventListener("popup.how-it-works", this.openByEvent);
  },
  beforeDestroy() {
    fakeWindow.removeEventListener("popup.how-it-works", this.openByEvent);
  },
  methods: {
    openByEvent() {
      this.visible = true;
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/scss/global.scss";
@use "@/assets/scss/font-sf-pro.scss";

$p: ".popup";

.popup-how-it-works {
  #{$p} {
    &__content-container {
      border-radius: 0;
    }

    &__close {
      @include mixin.size-md {
        position: fixed;
        top: 12px;
        right: 12px;
        width: 32px;
        padding: 10px;
        border-radius: 50%;
        box-shadow: 1px 4px 20px rgba(var.$secondary, 0.35);
        background: var.$white;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.popup-how-it-works {
  &__container {
    * {
      box-sizing: border-box;
    }
  }

  &__title {
    font-size: 24px;
  }

  &__what {
    padding: 28px 0 32px;

    @include mixin.size-lg-min {
      padding: 16px 0 49px;
    }

    &-question,
    &-title {
      margin-bottom: 12px;
    }

    &-title {
      @include mixin.size-lg-min {
        padding: 0 16.5%;
      }
    }

    &-items {
      @include mixin.size-lg-min {
        display: flex;
        flex-wrap: wrap;
      }
    }

    &-item {
      padding: 0 2.5%;
      margin-top: 20px;

      @include mixin.size-lg-min {
        width: percentage(func.divide(1, 3));
      }

      &-image {
        @include mixin.aspect-ratio();
        width: 120px;
        border-radius: 50%;
        margin: 0 auto 20px;

        @include mixin.size-lg-min {
          margin-bottom: 24px;
        }
      }

      &-title {
        margin-bottom: 6px;
      }
    }
  }

  &__benefits {
    padding: 8px 0 20px;
    background: var.$gray-light;
    margin: 0 -24px;

    @include mixin.size-lg-min {
      padding: 36px 0 48px;
    }

    &-container {
      padding: 0 var.$inner-pd;

      @include mixin.size-lg-min {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -4px;
        padding: 0 var.$inner-pd-md;
      }
    }

    &-item {
      display: flex;
      margin-top: 12px;

      @include mixin.size-lg-min {
        width: 25%;
        padding: 0 4px;
      }
    }

    &-card {
      width: 100%;
      background: var.$white;
      border-radius: var.$rounded-16;
      padding: 20px;

      @include mixin.size-lg-min {
        border-radius: var.$rounded-24;
        padding: 24px 20px;
      }
    }

    &-icon {
      width: 20px;
      margin: 0 auto 12px;

      @include mixin.size-lg-min {
        margin-bottom: 16px;
      }
    }

    &-title {
      margin-bottom: 6px;
    }
  }

  $steps-item-mt: 32px;
  $steps-index-size: 24px;

  &__steps {
    padding: 32px 0 40px;

    @include mixin.size-lg-min {
      padding: 48px 0 56px;
    }

    &-list {
      position: relative;

      @include mixin.size-lg-min {
        display: flex;

        &::before {
          @include mixin.decor();
          content: "";
          display: block;
          height: 2px;
          width: percentage(func.divide(2, 3));
          background: var.$gray-light;
          position: absolute;
          top: $steps-item-mt + func.divide($steps-index-size, 2);
          left: 50%;
          transform: translate(-50%, -1px);
        }
      }
    }

    &-item {
      margin-top: $steps-item-mt;

      @include mixin.size-lg-min {
        width: percentage(func.divide(1, 3));
        padding: 0 2.5%;
      }
    }

    &-index {
      width: $steps-index-size;
      margin: 0 auto 20px;
      position: relative;

      @include mixin.size-lg-min {
        &::before {
          @include mixin.decor();
          @include mixin.full-pos(
            percentage(func.divide(-8px, $steps-index-size))
          );
          content: "";
          display: block;
          position: absolute;
          background: var.$white;
        }
      }

      &-container {
        @include mixin.aspect-ratio();
        display: flex;
        align-items: center;
        justify-content: center;
        background: var.$gray-light;
        border-radius: 50%;
        position: relative;
      }
    }

    &-title {
      margin-bottom: 6px;

      >>> a {
        color: inherit;
        text-decoration: underline;
      }
    }
  }

  &__close {
    padding-bottom: 26px;

    @include mixin.size-lg-min {
      padding-bottom: 12px;
    }
  }
}
</style>
