import Vue from "vue";
import VueLazyload from "vue-lazyload";
import PortalVue from "portal-vue";
import VScrollLock from "v-scroll-lock";
import App from "./app.vue";
import store from "@/store";
import { i18n } from "@/plugins/i18n";

Vue.use(VueLazyload, {
  lazyComponent: true,
  observer: true,
  observerOptions: {
    rootMargin: `${document.documentElement.clientHeight}px 0px`,
  },
});

Vue.use(PortalVue);

Vue.use(VScrollLock, {
  bodyScrollOptions: {
    reserveScrollBarGap: true,
    allowTouchMove: (el) => el.closest(".multiselect__content-wrapper"),
  },
});

new Vue({
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#how-it-works");
